import React from 'react'
import "../styles/TeamSection.css"
import { ReactComponent as Check} from '../icons/Check.svg';
import { ReactComponent as Photo} from '../assets/TeamPhoto.svg';
import TeamPhoto from "../assets/team_photo.png";



function teamSection() {
    return (
        <div className='team-wrapper'>
            <div className='left' >
                <img src={TeamPhoto} alt="photo of shaking hands" className="ellipse-image"/>
            </div>

            <div className='right'>

                <span className='top-title'>Introducing Our Team</span>
                <span className='bottom-title'>Shape the future of healthcare and enhancing global health</span>

                <div className='body-info'>

                    <div className='sub-text'>Our crew encompasses wide expertise in healthcare, startups, consumer app development, customer experience, marketing, and product design. The entire team shares the drive and bond of many first generation immigrants in our hope to make a difference for future international students and immigrants.</div>
                
                    <div className='checked-info'> <Check/>We have Healthcare experts with deep medical knowledge</div>
                    <div className='checked-info'> <Check/>Tech specialists with advanced technology skills</div>

                    <button className='but' id="about-us" style={{ display: 'none' }}>Meet the team</button>
                </div>
                
            </div>

            
            

        </div>
    );
}

export default teamSection;