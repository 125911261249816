import React, { useState, useEffect } from "react";
import "../styles/translationHandler.css";

const TranslationHandler = ({ data, onTranslationComplete }) => {
  const [selectedLanguage, setSelectedLanguage] = useState(""); // set default to empty 
  const [isTranslating, setIsTranslating] = useState(false);
  const [originalData, setOriginalData] = useState([]); // store original OCR data to avoid multiple chained translation bug

  const BASE_URL = "https://us-central1-vaccine-genie.cloudfunctions.net";

  // Store original OCR data when component mounts or data updates
  useEffect(() => {
    if (data && originalData.length === 0) {
      setOriginalData(data);
    }
  }, [data]);
  
  const translateText = async (text, targetLanguage) => {
    try {
      const response = await fetch(`${BASE_URL}/translate`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ text, targetLanguage }),
      });

      const data = await response.json();
      return data.translatedText || text; // Fallback to original text
    } catch (error) {
      console.error("Translation error:", error);
      return text;
    }
  };

  const translateData = async (targetLanguage) => {
    if (!Array.isArray(originalData) || originalData.length === 0) {
      console.error("translateData error: originalData is not an array or is empty", originalData);
      return [];
    }
  
    return await Promise.all(
      originalData.map(async (row) => {
        if (typeof row !== "object" || row === null) return row;
        const translatedRow = { ...row };
        translatedRow.vaccineName = await translateText(row.vaccineName || "", targetLanguage);
        return translatedRow;
      })
    );
  };
  

  const translateHeaders = async (targetLanguage) => {
    const headers = {
      vaccineName: "Vaccine Name",
      vaccinationDate: "Vaccination Date(s)",  
      addAnotherDate: "Add Another Date",
      removeVaccine: "Remove Vaccine",
      addNewVaccine: "Add New Vaccine"
    };

    const translatedHeaders = {};
    for (const [key, value] of Object.entries(headers)) {
      translatedHeaders[key] = await translateText(value, targetLanguage);
    }
    return translatedHeaders;
  };



  const handleTranslation = async () => {
    console.log("entered handleTranslation")
    if (!selectedLanguage) return; // Prevent translation if no language selected

    
    setIsTranslating(true);
    console.log("is Translating")

    try {
      const translatedData = await translateData(selectedLanguage);
      const translatedHeaders = await translateHeaders(selectedLanguage);
      onTranslationComplete(translatedData, translatedHeaders);
    } catch (error) {
      console.error("Failed to translate:", error);
    } finally {
      setIsTranslating(false);
    }
  };

  return (
    <div className="translation-container">
    <label htmlFor="language-selector" className="language-label">Select a Language:</label>
    <select
        id="language-selector"
        className="language-dropdown"
        value={selectedLanguage}
        onChange={(e) => setSelectedLanguage(e.target.value)}
        disabled={isTranslating}
    >
        <option value="">
        Options
        </option>
        <option value="ar">Arabic</option>
        <option value="zh">Chinese</option>
        <option value="en">English</option>
        <option value="fr">French</option>
        <option value="it">Italian</option>
        <option value="ru">Russian</option>
        <option value="es">Spanish</option>
        <option value="uk">Ukrainian</option>   
        <option value="vi">Vietnamese</option>
        
    </select>

    <button className="translate-button" onClick={handleTranslation} disabled={isTranslating}>
        Translate
    </button>


    {isTranslating && <p className="translation-status">Translating...</p>}
    </div>

  );
};

export default TranslationHandler;