import React from 'react'
import '../styles/serviceCardUI.css'

const Card = props => {
    return (
        <div className='card text-left shadow'>

            <div className='service-card-body text-dark'>
                <div className='icon'>
                    <img src={props.imgsrc} alt='looking glass' className='img'/>
                </div>

                <div className='card-title' id='main'>
                    {props.title}
                </div>

                <div className='card-title text-secondary'>
                    {props.body}
                </div>

            </div>

        </div>
    );
};

export default Card;
