import Team from '../components/teamMembers';
import '../styles/feedbackSection.css'


const TeamMembersSection = () => {
    return (
      <div className='feedback-wrapper'>

        <div className="titles">
            <div className="t-title">Our Team</div>
        </div>
        <Team/>
      </div>
    );
  };
  
  export default TeamMembersSection;