import React, { useState, useEffect } from "react";
import "../styles/editableTable.css";
import { ReactComponent as Remove } from '../icons/trash.svg';
import TranslationHandler from "./translationHandler.js";
import DownloadButton from "./downloadButton.js";

// Converts dates from MM/DD/YYYY format to ISO format (YYYY-MM-DD) for input compatibility
const convertToISOFormat = (dateStr) => {
  if (!dateStr) return "";
  const parts = dateStr.split("/");
  if (parts.length !== 3) return dateStr;
  let [month, day, year] = parts;
  if (year.length === 2) year = "20" + year; // Assume 20xx for two-digit years
  return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
};

const EditableTable = ({ ocrData }) => {
  const [tableData, setTableData] = useState([]);
  const [translatedHeaders, setTranslatedHeaders] = useState({
    vaccineName: "Vaccine Name",
    vaccinationDate: "Vaccination Date(s)",
    addAnotherDate: "Add Another Date",
    removeVaccine: "Remove Vaccine",
    addNewVaccine: "Add New Vaccine"
  });

  // Load and parse OCR data into table format
  useEffect(() => {
    if (!ocrData) return;
    try {
      const parsedData = typeof ocrData === "string" ? JSON.parse(ocrData) : ocrData;

      const updatedData = Array.isArray(parsedData)
        ? parsedData.map(item => ({
            vaccineName: item.vaccineName || "",
            vaccinationDates: Array.isArray(item.vaccinationEvents)
              ? item.vaccinationEvents
                  .map(event => event.date ? convertToISOFormat(event.date) : "")
                  .filter(date => date !== "")
              : [""]
          }))
        : [];

      console.log("Processed Data:", updatedData); // Debugging log
      setTableData(updatedData);
    } catch (error) {
      console.error("Error parsing ocrData:", error);
    }
  }, [ocrData]);

  // Handle user input changes
  const handleChange = (e, rowIndex, field, dateIndex = null) => {
    const updatedTable = [...tableData];

    if (dateIndex !== null) {
      updatedTable[rowIndex].vaccinationDates[dateIndex] = e.target.value;
    } else {
      updatedTable[rowIndex][field] = e.target.value;
    }

    setTableData(updatedTable);
  };

  // Add a new vaccination date input
  const addVaccinationDate = (rowIndex) => {
    const updatedTable = [...tableData];
    updatedTable[rowIndex].vaccinationDates.push("");
    setTableData(updatedTable);
  };

  // Remove a specific vaccination date input
  const removeVaccinationDate = (rowIndex, dateIndex) => {
    const updatedTable = [...tableData];
    updatedTable[rowIndex].vaccinationDates.splice(dateIndex, 1);
    setTableData(updatedTable);
  };

  // Add a new row for a vaccine
  const addRow = () => {
    setTableData([...tableData, { vaccineName: "", vaccinationDates: [""] }]);
  };

  // Remove a row for a vaccine
  const removeVaccine = (rowIndex) => {
    const updatedTable = [...tableData];
    updatedTable.splice(rowIndex, 1);
    setTableData(updatedTable);
  };

  const handleTranslationComplete = (translatedData, headers) => {
    setTableData(translatedData); // Update the table with translated data
    setTranslatedHeaders(headers);
  };

  return (
    <div>
      {/* Handles translations for table data and headers */}
      <TranslationHandler data={tableData} onTranslationComplete={handleTranslationComplete} />

      <div className="table-container">
        <table className="table" style={{ borderCollapse: "collapse", width: "100%" }}>
          <thead className="table_details">
            <tr className="table-headers">
              <th>{translatedHeaders.vaccineName}</th>
              <th>{translatedHeaders.vaccinationDate}</th>
            </tr>
          </thead>
          {Array.isArray(tableData) &&
            tableData.map((row, rowIndex) => (
              <tbody key={rowIndex} className="vaccine-group">
                <tr>
                  <td>
                    {/* Input for vaccine name */}
                    <input
                      id="vaccine-name"
                      className="input-table"
                      type="text"
                      value={row.vaccineName}
                      onChange={(e) => handleChange(e, rowIndex, "vaccineName")}
                    />
                  </td>
                  <td>
                    {/* Inputs for vaccination dates */}
                    {row.vaccinationDates.map((date, dateIndex) => (
                      <div key={dateIndex} className="date-group">
                        <input
                          className="input-table"
                          type="date"
                          value={date || ""}
                          onChange={(e) => handleChange(e, rowIndex, "date", dateIndex)}
                        />
                        {/* Button to remove individual date */}
                        <button
                          className="remove-date-button"
                          onClick={() => removeVaccinationDate(rowIndex, dateIndex)}
                        >
                          <Remove className="table-icon" />
                        </button>
                      </div>
                    ))}
                    {/* Button to add another date input */}
                    <button
                      id="add-date"
                      className="edit-table-button"
                      onClick={() => addVaccinationDate(rowIndex)}
                    >
                      {translatedHeaders.addAnotherDate}
                    </button>
                  </td>
                  <td>
                    {/* Button to remove entire vaccine row */}
                    <button
                      id="remove-vaccine"
                      className="edit-table-button"
                      onClick={() => removeVaccine(rowIndex)}
                    >
                      {translatedHeaders.removeVaccine}
                    </button>
                  </td>
                </tr>
              </tbody>
            ))}
        </table>
        {/* Button to add a new vaccine row */}
        <button id="add-vaccine" className="edit-table-button" onClick={addRow}>
          {translatedHeaders.addNewVaccine}
        </button>
      </div>

      {/* Download button for exporting table data */}
      <DownloadButton tableData={tableData} translatedHeaders={translatedHeaders} />
    </div>
  );
};

export default EditableTable;
