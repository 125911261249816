import React, { useState, useRef } from 'react';
import '../styles/instructionsPopUp.css'; // Create a CSS file for styling

const InstructionsPopUp = ({ isOpen, onClose, videoClips }) => {
  if (!isOpen) return null;

  const handleOverlayClick = (event) => {
    if (event.target.classList.contains('popup-overlay')) {
      onClose();
    }
  };

  return (
    <div className="popup-overlay" onClick={handleOverlayClick}>
      <div className="popup-content">
        <button className="close-button" onClick={onClose}>
          x
        </button>
        <Slideshow videoClips={videoClips} />
      </div>
    </div>
  );
};

const Slideshow = ({ videoClips }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const videoRef = useRef(null);

  const slideTexts = [
    'Drag and drop or select your file(s) then click "Convert"!',
    'Select a language to translate your records!',
    'Download your vaccine information in either PDF, DOCX, or SMART FHIR format!',
  ];

  const handleNext = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % videoClips.length);
  };

  const handlePrev = () => {
    setCurrentSlide((prevSlide) => (prevSlide - 1 + videoClips.length) % videoClips.length);
  };

  return (
    <div className="slideshow">
      <video ref={videoRef} src={videoClips[currentSlide]} autoPlay loop muted />
      <p className='slide-text'>{slideTexts[currentSlide]}</p>
      <div className="navigation">
        <button onClick={handlePrev}>&lt;</button>
        <button onClick={handleNext}>&gt;</button>
      </div>
    </div>
  );
};

export default InstructionsPopUp;