import React, { useState } from "react";
import UploadForm from '../components/uploadForm';
import Layout from "../components/layout";
import InstructionsPopUp from '../components/instructionsPopUp.js';
import tutorialIcon from '../icons/icons8-info.svg';
import '../styles/uploadPage.css'



const UploadPage = () => {
    const [isPopUpOpen, setIsPopUpOpen] = useState(true);
    
      const videoClips = [
        '/media/convert.mp4',
        '/media/translate.mp4',
        '/media/download.mp4'
      ]
    
      const openPopup = () => {
        setIsPopUpOpen(true);
      };
    
      const closePopup = () => {
        setIsPopUpOpen(false);
      };
    
    
    
    
    return (
        <>
        <Layout>
            <button className="tutorial-button" onClick={openPopup}><img src={tutorialIcon} alt="Feature Tutorial" /></button>
            <InstructionsPopUp isOpen={isPopUpOpen} onClose={closePopup} videoClips={videoClips} />
            <UploadForm />
        </Layout>
        </>
    );
};

export default UploadPage;