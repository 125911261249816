import React from 'react'
import "../styles/introSection.css"
import "../styles/homeNavBar.css"
import { ReactComponent as Graphic} from '../assets/introGraphic.svg';
import { useNavigate } from "react-router-dom";
import { ReactComponent as Arrow} from '../icons/Arrow.svg';


function IntroSection() {
    const navigate = useNavigate();
    return (
        
        <div>
            <div className="wrapper" >
                
                {/* think about making blue blob the background of main txt*/}
                <div className='side' id="left">
                    <div className="main-txt">
                        Hassle-Free Universal <br/>Vaccine Records <br/>For <span id="everyone-txt">Everyone</span>
                    </div>

                    <div className='sub-txt'>
                    Vaccine Genie is a new way to get your vaccine records anywhere. 
                    </div>

                    <button className='but' id="about-us" style={{ display: 'none' }}>About Us</button>
                </div>

                <div className='side' id="graphic-svg-container">
                    <Graphic id="intro-graphic"/>

                </div>

            </div>

            <div className='wrapperUploadButton'>
                    <button
                        id="uploadButton"
                        onClick={() => navigate("/upload")} 
                    >
                        Try our demo today!

                        <Arrow className="button-arrow" />

                    </button>

                </div>
        </div>
    );
}

export default IntroSection;