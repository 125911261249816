import React, { useState, useEffect, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { storage } from '../firebase';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import Parse from './parseAndFillTable';
import '../styles/uploadForm.css';
import UploadFile from './uploadFile';
import { ReactComponent as Upload } from '../icons/upload.svg';
import { ReactComponent as Drag } from '../icons/cloud-add.svg';

function UploadForm() {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [compiledOcrText, setCompiledOcrText] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [progressStep, setProgressStep] = useState(0); // Progress step for the progress bar
  const [data, setData] = useState(null); // For fetched data
  

  const stages = [
    'Uploading Files',
    'Scanning Files',
    'Processing Data',
    'Filling Table',
    'Results Ready',
  ];

  // production url
  const BASE_URL = "https://us-central1-vaccine-genie.cloudfunctions.net";
  
  // testing url
  //const BASE_URL = "http://localhost:5001/vaccine-genie/us-central1"
  const fetchOCRData = async (endpoint, downloadURL, file) => {
    let response;
    
    if (endpoint == "pdf") {
      response = await fetch(`${BASE_URL}/ocr_pdf`, {
        method: 'POST',
        body: JSON.stringify({ fileName: file.name }),
        headers: {
          'Content-Type': 'application/json',
        },
      });
    }
    
    else if (endpoint == "image") {
      response = await fetch(`${BASE_URL}/ocr_images`, {
        method: 'POST',
        body: JSON.stringify({ fileUrl: downloadURL }),
        headers: {
          'Content-Type': 'application/json',
        },
      });
    }

    if (!response.ok || !response) {
      throw new Error('Failed to process OCR');
    }

    return response.json();
  };

  const uploadFilesAndRunOCR = async (files) => {
    setIsLoading(true);
    setProgressStep(0); // Start at "Uploading Files"

    let compiledText = '';
    for (const file of files) {
      try {
        // Step 1: Upload files
        setProgressStep(1);
        const storageRef = ref(storage, `files/${file.name}`);
        const uploadTask = await uploadBytesResumable(storageRef, file);
        const downloadURL = await getDownloadURL(uploadTask.ref);

        // Step 2: Perform OCR scanning based on file type or text
        const fileType =  file.type;
        let ocrData;

        if (fileType === 'application/pdf') {
          // use api for detecting text in pdf files
          ocrData = await fetchOCRData("pdf", downloadURL, file);
        } else if (fileType === 'image/jpeg' || 'image/png' || 'image/jpeg') {
          // find way to determine if document is handwritten const isHandwritten = 
          // const endpoint = isHandwritten ? `${BASE_URL}/ocr/handwritten` : `${BASE_URL}/ocr/typed`;

          // include api endpoint in function call for ocr data
          ocrData = await fetchOCRData("image", downloadURL, file);
        
        } else {
          console.warm(`Unsupported file type: ${fileType}`);
          continue;
        }

        
        setProgressStep(2);
        
        compiledText += `${ocrData.text}\n`;

      } catch (error) {
        console.error('Error processing file:', file.name, error);
      }
    }

    // Step 3: Process Data
    setProgressStep(3);
    setCompiledOcrText(compiledText);
  };

  const onDrop = useCallback((acceptedFiles) => {
    setSelectedFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
  }, []);

  const handleConvertClick = () => {
    if (selectedFiles.length === 0) {
      alert('Please upload files before clicking Convert.');
      return;
    }
    uploadFilesAndRunOCR(selectedFiles);
  };

  const handleParseComplete = () => {
    setProgressStep(4); // Update to "Results Ready"
    setIsLoading(false);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'image/jpeg': [],
      'image/png': [],
      'application/pdf': [],
    },
    multiple: true,
  });

  useEffect(() => {
    if (compiledOcrText) {
      // Fetch the parsed data once OCR text is compiled
      setData({ message: "OCR data compiled successfully" });
    }
  }, [compiledOcrText]); // Runs when compiledOcrText is updated

  return (
    <div className="wrapper-upload">
      
      <div className="upload-header">
        <div id="upload-logo">
          <Upload />
        </div>
        <div className="upload-text">
          <span id="top-text">Upload files</span>
          <span id="lower-text">Select and upload multiple files</span>
        </div>
      </div>

      <div className="drag-drop-area" {...getRootProps()}>
        <Drag />
        <input {...getInputProps()} />
        {isDragActive ? (
          <div className="drag-and-drop-info">
            <span id="drag-and-drop-top-text">Choose a file or drag and drop them here</span>
            <span id="drag-and-drop-bot-text">JPEG, PNG, and PDF formats up to 50MB (50000 KB)</span>
          </div>
        ) : (
          <div className="drag-and-drop-info">
            <span id="drag-and-drop-top-text">Choose a file or drag and drop them here</span>
            <span id="drag-and-drop-bot-text">JPEG, PNG, and PDF formats up to 50MB (50000 KB)</span>
          </div>
        )}
        <label id="browse-files">Browse Files</label>
      </div>

      <div className="selected-files">
        <div className="files">
          {selectedFiles.map((file) => (
            <UploadFile
              key={file.name}
              file={file}
              onRemove={() => {
                setSelectedFiles((prevFiles) => prevFiles.filter((f) => f !== file));
              }}
            />
          ))}
        </div>
      </div>

      <div className='button-container'>
        <button
          id='convert-button'
          onClick={handleConvertClick}
          disabled={isLoading || selectedFiles.length === 0}
        >
          Convert
        </button>
      </div>

      {isLoading && progressStep < stages.length - 1 && (
        <div className="spinner-container">
          <div className="spinner"></div>
          <span className='stage-text'>{stages[progressStep]}</span>
        </div>
      )}

      <div className='table-component'>
        {compiledOcrText && (
          <Parse
            ocrText={compiledOcrText}
            onDataParsed={(data) => console.log('Parsed Data:', data)}
            onParseComplete={handleParseComplete}
          />
        )}
      </div>
    </div>
  );
}

export default UploadForm;
