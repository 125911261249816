import { useState, useEffect } from "react";
import { GoogleGenerativeAI } from "@google/generative-ai";
import EditableTable from "./editableTable";

function Parse({ ocrText, onDataParsed, onParseComplete }) {
  const [parsedData, setParsedData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [apiKey, setApiKey] = useState(null);

  useEffect(() => {
    fetch("/config.json")
      .then(response => response.json())
      .then(config => {
        setApiKey(config.REACT_APP_GEMINI_API_KEY);
      })
      .catch(error => console.error("Error loading API key:", error));
  }, []);

  useEffect(() => {
    console.log("OCR text received:", ocrText);

    if (ocrText && apiKey) {
      fetchData(ocrText);
    }
  }, [ocrText, apiKey]);

  const cleanJSONString = (str) => {
    str = str.trim();
    console.log("Raw API response:", str);

    // Remove any leading or trailing text outside of brackets
    const startIndex = str.indexOf("[");
    const endIndex = str.lastIndexOf("]");

    if (startIndex === -1 || endIndex === -1) {
      throw new Error("Invalid JSON format: No square brackets found");
    }

    str = str.slice(startIndex, endIndex + 1);

    console.log("Cleaned JSON string:", str);
    return str;
  };

  const fetchData = async (text) => {
    if (!apiKey) {
      console.error("API key not loaded yet!");
      return;
    }

    console.log("Fetching data with text:", text);
    setIsLoading(true);

    try {
      const genAI = new GoogleGenerativeAI(apiKey);
      const model = genAI.getGenerativeModel({ model: "gemini-1.5-flash" });

      const prompt = `
        Here is some OCR text containing vaccination details: "${text}". 
        Please extract the following categories: 
        - Vaccine name 
        - Vaccination date 
      
        If information is missing, leave it blank. Enclose property names in double quotes. All vaccines must have at least one vaccine event even if it must be empty. If a date uses '-' or '.' to format the date, convert to '/'.
        If dates are not in "MM/DD/YYYY" format, convert to this format if possible.

        IMPORTANT: Only return the data in a structured format like this with no other text or extra messages before or after the brackets. Always use '/' when writing dates: 
        [
          {
            "vaccineName": "...",
            "vaccinationEvents": [
              { "date": "MM/DD/YYYY" }
            ]
          }
        ]
      `;

      const result = await model.generateContent(prompt);
      console.log("Raw result object:", result);

      const response = await result.response;
      console.log("Response object:", response);

      const parsedText = response.text();
      console.log("API response text:", parsedText);

      const cleanedText = cleanJSONString(parsedText);
      console.log("Cleaned text before JSON parse:", cleanedText);

      const parsedJSON = JSON.parse(cleanedText);
      console.log("Parsed JSON:", parsedJSON);

      setParsedData(cleanedText);
      onDataParsed(parsedJSON);
    } catch (error) {
      console.error("Error categorizing OCR data:", error);
      setParsedData(null);
      onDataParsed(null);
    } finally {
      setIsLoading(false);
      if (onParseComplete) onParseComplete();
    }
  };

  return (
    <div className="container">
      <EditableTable ocrData={parsedData} />
    </div>
  );
}

export default Parse;
