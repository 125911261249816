import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import ArsheyaPhoto from '../assets/Arsheya Raj.jpg';
import EvelynPhoto from '../assets/Evelyn Fang.png';
import NathanPhoto from '../assets/Nathan.jpg';
import WilliamPhoto from '../assets/WilliamWang.jpg';
import LukePhoto from '../assets/luke.jpg';
import AdelinePhoto from '../assets/Adeline.jpg';
import ShamilPhoto from '../assets/Shamil.jpeg';
import VedikaPhoto from '../assets/Vedika Bataan.jpeg';
import KarissaPhoto from '../assets/Karissa_Marketing_Updated.jpg';
import MohitPhoto from '../assets/Mohit_Kukreja.jpeg';
import AlvinPhoto from '../assets/AlvinMa.jpg';
import DefaultPhoto from '../assets/Vaccine Genie New Logo 256x256.png';

const TeamGrid = () => {
const teamMembers = [
  
  {
    photo: EvelynPhoto,
    name: "Dr. Evelyn Fang",
    role: "Founder & CEO",
    description: "Boarded in Family and Internal Medicine in the United States. UW graduate student in Clinical Informatics and Patient Centered Technology (CIPCT) program. Worked in China, familiar with travel vaccine needs and advocate of primary prevention through vaccines.",
    linkedinUrl: "https://www.linkedin.com/in/evelyn-fang-b2307798/"
  },

  {
    photo: ArsheyaPhoto,
    name: "Arsheya Raj",
    role: "Founder & CTO",
    description: "MS in Computer Science and Software Engineering - School of STEM (Bothell) at University of Washington. Worked in India at as business technology analyst and as freelance application developer.",
    linkedinUrl: "https://www.linkedin.com/in/rajarsheya/"
  },
  
  {
    photo: WilliamPhoto,
    name: "William Shupeng Wang",
    role: "Project Manager",
    description: "NA",
    linkedinUrl: "https://www.linkedin.com/in/williamwanguw/"
  },

  {
    photo: LukePhoto,
    name: "Luke Sandoval",
    role: "Web Developer Lead",
    description: "BS in Computer Science",
    linkedinUrl: "https://www.linkedin.com/in/luke-samuel-sandoval/"
  },

  {
    photo: AdelinePhoto,
    name: "Adeline Li",
    role: "UX Designer",
    description: "NA",
    linkedinUrl: "https://www.linkedin.com/in/qili0103/"
  },

  {
    photo: NathanPhoto,
    name: "Nathan Alexander",
    role: "Mobile Developer Lead",
    description: "NA",
    linkedinUrl: "https://www.linkedin.com/in/nathan-alexander1/"
  },

  {
    photo: ShamilPhoto,
    name: "Shamil Khamrayev",
    role: "QA/QC",
    description: "NA",
    linkedinUrl: "https://www.linkedin.com/in/shamil-khamrayev/"
  },

  {
    photo: VedikaPhoto,
    name: "Vedika Bataan",
    role: "Mobile Developer",
    description: "N/A",
    linkedinUrl: "https://www.linkedin.com/in/vedika-bataan-8b76b6297/"
  },

  {
    photo: KarissaPhoto,
    name: "Karissa Brown",
    role: "Marketing Manager",
    description: "N/A",
    linkedinUrl: "https://www.linkedin.com/in/karissa-brown-b559022a8/"
  },
  
  {
    photo: MohitPhoto,
    name: "Mohit Kukreja",
    role: "Clinical Data Scientist",
    description: "N/A",
    linkedinUrl: "https://www.linkedin.com/in/mohitkukreja02/"
  },

  {
    photo: AlvinPhoto,
    name: "Alvin Ma",
    role: "Marketing Manager",
    description: "N/A",
    linkedinUrl: "https://www.linkedin.com/in/cheng-yiu-ma-b6405b162/"
  },

    {
    photo: DefaultPhoto,
    name: "Loc Dao",
    role: "Web Developer",
    description: "N/A",
    linkedinUrl: "https://www.linkedin.com/in/dhuuloc/"
  },
  
];

return (
  <Container className="py-4">
    <Row className="justify-content-center text-center g-4">
      {teamMembers.map((member, index) => (
        <Col 
        key={index}
        xs={12}      // Full width on small screens
        sm={6}       // Half width on small screens
        md={4}       // 3 columns on medium screens
        lg={3}       // 4 columns on large screens
        className="mb-3 d-flex justify-content-center"
      >
        <div className="card h-100 w-100 shadow text-center">
          <div className="text-center p-3">
            <div style={{ width: '150px', height: '150px', margin: '0 auto' }}>
              <img
                src={member.photo}
                alt={member.name}
                className="rounded-circle w-100 h-100 object-fit-cover"
              />
            </div>
            <div className="card-body">
              <h5 className="card-title mb-1">{member.name}</h5>
              <h6 className="card-subtitle mb-2 text-muted">{member.role}</h6>
              {member.linkedinUrl && (
                <a 
                  href={member.linkedinUrl} 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="btn btn-outline-primary btn-sm"
                >
                  LinkedIn
                </a>
              )}
            </div>
          </div>
        </div>
      </Col>
      
      ))}
    </Row>
  </Container>
);
};

export default TeamGrid;